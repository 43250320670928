.portfolio {
  padding-top: 2.5rem;

  .intro {
    font-weight: 300;
    display: flex;
    justify-content: center;
    align-items: center;

    h1, .copy {
      padding: 0 10px;
    }

    h1 {
      min-width: 100px;
      margin: 0;
      color: $color-btn-github;
      text-align: right;
      font-weight: 300;
      font-size: 1.25rem;
      line-height: 1.222em;
    }

    .pipe {
      width: 2px;
      height: 120px;
      margin: 0 6px;
      background: #333;
    }

    h2 {
      color: $color-btn-link;
      font-weight: 600;
      font-size: 1.125rem;
    }

    .copy {
      min-width: 190px;
      font-size: 0.875rem;

      p {
        display: none;
      }
    }

    @media #{$tablet} {

      & {
        padding: 0 20px;
      }

      h1 {
        min-width: 180px;
        padding: 0;
        font-size: 2.5rem;
      }

      .pipe {
        height: 200px;
        margin: 0 26px;
      }

      .copy {
        padding: 0;
        font-size: 1.25rem;
        line-height: 1.3em;

        h2 {

          br {
            display: none;
          }
        }

        p {
          display: block;
        }
      }
    }

    @media #{$tablet} {
      & {
        max-width: 900px;
        margin-right: auto;
        margin-left: auto;
        padding: 0;

        h1 {
          max-width: 320px;
        }

        .pipe {
          height: 170px;
        }
      }
    }
    
  }

  @import './project';

}