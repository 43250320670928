@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600&display=swap');

$color-nav: #fff;
$color-text: #333;
$color-btn-link: #3da11c; // #27a9f5;
$color-btn-github: #2183bd;
$color-btn-github-text-hover: rgba(255,255,255,1);

$font-stack-default: 'open sans', arial, 'helvetica neue', helvetica, sans-serif;
$font-stack-monospace: 'source code pro', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

$tablet: 'only screen and (min-width: 640px)';
$desktop: 'only screen and (min-width: 1025px)';