@import './vars';

* {
  box-sizing: border-box;
}

body {
  min-width: 320px;
  margin: 0;
  color: #333;
  font: normal normal 400 1rem/1.25em $font-stack-default;
  letter-spacing: 0.004rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: $font-stack-monospace;
}

.logo {
  $width: 50px;
  $height: 38px;
  width: $width;
  height: $height;
  background: transparent url('../imgs/logo-dh.png') no-repeat center center;
  background-size: $width $height;
  display: block;
  position: absolute;
  right: 20px;
}

@import './App';
@import './Navigation';
@import './Portfolio';
@import './About';
@import './Footer';

.ir {
  color: transparent;
  text-indent: -9999px;
  font: 0/0 a;
  overflow: hidden;
}