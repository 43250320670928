  .project article {

    &:nth-child(even) {
      background: #eee;
    }

    img {
      width: calc(100% - 40px);
      margin: 0 auto;
      display: block;
    }

    .text {
      margin-top: 40px;
      padding: 0 20px;

      h2 {
        margin-top: 60px;
        font-weight: 600;
        line-height: 1.125em;
      }

      dl {
        margin-bottom: 30px;
      }

      dt, dd {
        font-size: 0.875rem;
      }

      dt {
        margin-top: 10px;
        font-weight: 600;

        &:first-child {
          margin-top: 30px;
        }
      }

      dd {
        margin-left: 0;
      }

      a {
        width: 100%;
        padding: 14px 30px;
        color: $color-btn-link;
        text-decoration: none;
        text-align: center;
        font-weight: 600;
        display: block;
        border: 2px solid $color-btn-link;
        border-radius: 10px;
        cursor: pointer;

        &:hover, &:active {
          background: $color-btn-link;
          color: #fff;
        }

        &:last-child {
          margin-top: 25px;
        }

        &.github {
          color: $color-btn-github;
          border-color: $color-btn-github;

          &:hover {
            color: $color-btn-github-text-hover;
            background: $color-btn-github;
          }
        }
      }
    }

    @media #{$tablet} {
      & {
        max-width: 100%;
        margin: 0 auto;
        padding: 5rem 30px;
      }

      img {
        width: 100%;
        max-width: 670px;
        height: 100%;
      }

      .text {
        max-width: 670px;
        margin: 40px auto 0;
        padding: 0;

        h2 {
          font-size: 1.5rem;
        }

        p {
          font-size: 1.25rem;
          line-height: 1.2em;
        }
      }
    }

    @media only screen and (min-width: 1158px) {

      .text {
        a {
          width: 47%;
          margin: 0  6% 0 0;
          display: inline-block;
  
          &:last-child {
            margin: 0;
          }
        }
      }
    }

    @media #{$desktop} {

      .container {
        width: 100%;
        max-width: 1200px;
        margin-right: auto;
        margin-left: auto;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        div.text {
          margin-top: 0;
          padding-left: 60px;

          h2 {
            margin-top: 0;
          }
        }

      }

      &:nth-child(odd) {

        .container {
          flex-flow: row-reverse nowrap;
          
          .text {
            padding-right: 60px;
            padding-left: 0;
          }
        }

      }

    }
  }