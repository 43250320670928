nav {

  .close {
    height: 0px;
  }

  #menu, #menu-list {
    transition: height 0.25s ease-in-out;
    overflow: hidden;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  #menu {
    width: 44px;
    height: 36px;
    margin: 0 10px 10px;
    color: transparent;
    font: 0/0 a;
    cursor: pointer;

    &:before {
      content: '';
      width: 44px;
      height: 6px;
      background: #333;
      display: block;
      border-radius: 3px;
      box-shadow: 0 14px 0 #000, 0 28px 0 #000;
    }
  }

  #menu-list {

    ul {
      margin-top: 10px;
      padding: 0;
      border-top: 1px solid #333;
    }

    li {
      font-weight: 600;
      letter-spacing: 0.01rem;
      border-bottom: 1px solid #333;
      text-align: center;

      a {
        color: #333;
        font-size: 1.25rem;
        line-height: 2.5rem;
        text-decoration: none;
      }
    }
  }

  @media #{$tablet} {
  
    #menu {
      display: none;
      background: red;
    }
  
    #menu-list {
      height: auto !important;
      display: block;

      ul {
        display: flex;
        list-style-type: none;
        border-top: none;
      }

      li {
        padding: 0 10px;
        border-bottom: none;

        &:last-child {
          padding-right: 0;
        }

        a {
          font-size: 1rem;
        }
      }
    }
  
  }
}