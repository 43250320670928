footer {
  padding-top: 40px;
  background: #eee;
  font-size: 0.875em;
  display: flex;
  flex-direction: column-reverse;

  ul {
    margin: 0 auto;
    padding: 0;
    text-align: center;
    list-style-type: none;

    li {
      font-weight: 600;
      letter-spacing: 0.01rem;
      text-align: center;

      a {
        color: #333;
        font-weight: 300;
        font-size: 0.875 rem;
        line-height: 2.5rem;
        text-decoration: none;
      }
    }
  }

  .logo {
    margin: 70px auto 0;
    position: static;
  }

  p {
    margin-bottom: 30px;
    text-align: center;
  }

  @media #{$tablet} {
    & {
      padding: 40px 20px 40px;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    p {
      margin: 0;
    }

    .logo {
      margin: 0 20px;
    }

    ul {
      margin: 0;
      display: flex;

      li {
        margin: 0 14px;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}